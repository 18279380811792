import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import {
  FormLayout,
  TextField,
  BlockStack,
  Select,
  Button,
  Card,
  Banner,
  Modal,
  Tooltip,
  InlineStack,
  InlineGrid,
  Checkbox,
  Badge,
  ProgressBar,
  Text,
} from "@shopify/polaris";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";
import {
  useGetTeamsQuery,
  useCreateVendorPaymentConfirmationMutation,
  useCreateVendorPaymentOpportunityMutation,
} from "../../../services/api";
import {
  formatCurrency,
  stateOptionsShort,
} from "../../../utilities";
// import { CurrentTeamContext } from "../../../contexts/Team";
import { CurrentContactContext } from "../../../contexts/Contact";

const InvoiceConfirmForm = forwardRef((props, ref) => {
  /* TODO
  //
     This class utilizes a customer_client - I do not recall the initial motivation
     for this naming difference (customer_client vs. client_account), but following
     the Teams migration, the customer_client here will be the Client.  It is safe
     to refer to it as client.
  //
  */

  const {
    handleErrorMessage,
    invoiceData,
    vendorPaymentId,
    onConfirmationLoading,
  } = props;

  const history = useHistory();
  const navigateToOpportunityShow = useCallback(
    (clientId, opportunityId) => {
      history.push(`/clients/${clientId}/opportunities/${opportunityId}`);
    },
    [history]
  );

  const { currentContact } = useContext(CurrentContactContext);

  const [createVendorPaymentConfirmation, {}] =
    useCreateVendorPaymentConfirmationMutation();

  const [createVendorPaymentOpportunity, { isLoading }] =
    useCreateVendorPaymentOpportunityMutation();

  const { data: teamData = [], isLoading: queryIsLoading } = useGetTeamsQuery({
    contactId: currentContact.id,
  });

  const [teams, setTeams] = useState([]);
  const [confirmedClient, setConfirmedClient] = useState({});
  // const [confirmedContact, setConfirmedContact] = useState({});
  const [confirmedVendor, setConfirmedVendor] = useState({});
  const [confirmedVendorPayment, setConfirmedVendorPayment] = useState({});

  const [matchedClient, setMatchedClient] = useState({});
  // const [matchedContact, setMatchedContact] = useState({});
  const [matchedVendor, setMatchedVendor] = useState({});

  const [submitMatchedClient, setSubmitMatchedClient] = useState(false);
  const [submitMatchedContact, setSubmitMatchedContact] = useState(false);
  const [submitMatchedVendor, setSubmitMatchedVendor] = useState(false);
  const [teamId, setTeamId] = useState(teamData[0]?.id);
  const [vendorLocked, setVendorLocked] = useState(
    teamData[0]?.attributes?.vendor_locked
  );
  const [selectedTeam, setSelectedTeam] = useState(teamData[0]);
  const handleTeamSelection = useCallback(
    (value) => {
      const selectedTeam = teamData.find(
        (team) => team?.attributes?.name == value
      );
      setSelectedTeam(selectedTeam);
    },
    [teamData]
  );

  useEffect(() => {
    if (teamData.length > 0) {
      const teamOption = teamData.map((t) => t?.attributes?.name);
      setTeams(teamOption);

      setSelectedTeam(teamData[0]);
    }
  }, [teamData]);

  useEffect(() => {
    if (selectedTeam) {
      setTeamId(selectedTeam?.id);
      setVendorLocked(selectedTeam?.attributes?.vendor_locked);
    }
  }, [selectedTeam, teamData]);

  const basePaymentField = useField({
    value: `${formatCurrency(invoiceData?.base_payment)}`,
    validates: [notEmpty("Base payment is required.")],
  });

  const vendorNameField = useField({
    value: invoiceData?.vendor_name,
    validates: [notEmpty("Vendor name is required.")],
  });

  const { fields, submit, submitting, reset, submitErrors, makeClean, dirty } =
    useForm({
      fields: {
        // CUSTOMER ACCOUNT
        customer_client_name: useField({
          value: invoiceData?.customer_client?.name,
          validates: [notEmpty("Account name is required")],
        }),
        customer_client_billing_street: useField({
          value: invoiceData?.customer_client?.billing_street,
          validates: [notEmpty("Account street address is required")],
        }),
        customer_client_billing_city: useField({
          value: invoiceData?.customer_client?.billing_city,
          validates: [],
        }),
        customer_client_billing_state: useField({
          value:
            invoiceData?.customer_client?.billing_state || undefined,
          validates: [],
        }),
        customer_client_billing_postal_code: useField({
          value: invoiceData?.customer_client?.billing_postal_code,
          validates: [],
        }),

        // CUSTOMER CONTACT
        // ...(isAdmin && contactFields),

        // QUOTE/INVOICE DETAILS
        ...(invoiceData?.base_payment && {
          base_payment: basePaymentField,
        }),

        ...(!vendorLocked && { vendor_name: vendorNameField }),

        product_type: useField({
          value: invoiceData?.quote_type,
          validates: [],
        }),
        product_description: useField({
          value: invoiceData?.product_description,
          validates: [],
        }),
        invoice_number: useField({
          value: invoiceData?.invoice?.invoice_number,
          validates: [notEmpty("Quote number is required.")],
        }),
        invoice_amount: useField({
          value: `${formatCurrency(
            invoiceData?.invoice?.invoice_amount?.value
          )}`,
          validates: [notEmpty("Quote amount is required.")],
        }),
        invoice_type: useField({
          value: invoiceData?.tags?.includes("saas")
            ? "saas"
            : "standard",
          validates: [],
        }),
        sales_tax: useField({
          value: `${formatCurrency(invoiceData?.sales_tax)}`,
          validates: [],
        }),

        // SHIPPING DETAILS
        location_address: useField({
          value: invoiceData?.invoice?.location_address,
          validates: [notEmpty("Shipping street is required.")],
        }),
        location_city: useField({
          value: invoiceData?.invoice?.location_city,
          validates: [],
        }),
        location_state: useField({
          value: invoiceData?.invoice?.location_state || undefined,
          validates: [],
        }),
        location_zip: useField({
          value: invoiceData?.invoice?.location_zip,
          validates: [],
        }),
      },
      async onSubmit(form) {
        onConfirmationLoading(true);
        const payload = formPayload(form);

        setConfirmedClient(payload.customer_client);
        setConfirmedVendor(payload.vendor);

        setMatchedClient({});
        setSubmitMatchedClient(false);

        setMatchedVendor({});
        setSubmitMatchedVendor(false);

        return createVendorPaymentConfirmation({
          vendorPaymentId: vendorPaymentId,
          vendorLocked: vendorLocked,
          teamId: teamId,
          ...payload,
        })
          .unwrap()
          .then((response) => {
            const hasMatches = handleConfirmResponseMatches(response);
            if (hasMatches) {
              handleOpenMatchModal();
            } else {
              handleSubmitPayloadAsEntered(payload);
            }

            onConfirmationLoading(false);

            return { status: "success" };
          })
          .catch((error) => {
            onConfirmationLoading(false);
            console.log(error);
          });
      },
    });

  const formPayload = (form) => {
    const customer_client = {
      name: form.customer_client_name,
      billing_street: form.customer_client_billing_street,
      billing_city: form.customer_client_billing_city,
      billing_state: form.customer_client_billing_state,
      billing_postal_code: form.customer_client_billing_postal_code,
    };
    const purchase_option = {
      type: form.invoice_type,
    };
    const vendor_payment = {
      base_payment: form?.base_payment ? form.base_payment : undefined,
      product_type: form.product_type,
      product_description: form.product_description,
      invoice_number: form.invoice_number,
      invoice_amount: form.invoice_amount,
      sales_tax: form.sales_tax,
      location_address: form.location_address,
      location_city: form.location_city,
      location_state: form.location_state,
      location_zip: form.location_zip,
    };
    const vendor = {
      name: form.vendor_name,
    };
    return {
      customer_client: { ...customer_client },
      purchase_option: { ...purchase_option },
      vendor_payment: { ...vendor_payment },
      vendor: { ...vendor },
    };
  };

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const handleConfirmResponseMatches = (response) => {
    let requiresClientMatch = false,
      requiresVendorMatch = false;

    if (response.customer_client) {
      setMatchedClient(response.customer_client);
      setSubmitMatchedClient(true);
      requiresClientMatch = true;
    }
    if (response.vendor) {
      if (selectedTeam.attributes.vendor_locked) {
        setSubmitMatchedVendor(false);
        requiresVendorMatch = false;
      } else {
        setMatchedVendor(response.vendor);
        setSubmitMatchedVendor(true);
        requiresVendorMatch = true;
      }
    }
    if (response.vendor_payment) {
      setConfirmedVendorPayment(response.vendor_payment);
    }

    return requiresClientMatch || requiresVendorMatch;
  };

  const [activeMatchModal, setActiveMatchModal] = useState(false);
  const handleOpenMatchModal = () => {
    setActiveMatchModal(true);
  };
  const handleCloseModal = () => {
    setActiveMatchModal(false);
  };

  const handleClientSelectionChange = useCallback(
    (newChecked) => setSubmitMatchedClient(newChecked),
    []
  );
  const handleVendorSelectionChange = useCallback(
    (newChecked) => setSubmitMatchedVendor(newChecked),
    []
  );

  const buildMatchPayload = useCallback(() => {
    return {
      customer_client: submitMatchedClient ? matchedClient : confirmedClient,
      purchase_option: {
        type: fields.invoice_type.value,
      },
      vendor: submitMatchedVendor ? matchedVendor : confirmedVendor,
    };
  }, [
    confirmedClient,
    confirmedVendor,
    fields.invoice_type.value,
    matchedClient,
    matchedVendor,
    submitMatchedClient,
    submitMatchedVendor,
  ]);

  const handleSubmitPayloadAsEntered = (payload) => {
    const payloadStructured = {
      customer_client: payload.customer_client,
      vendor: payload.vendor,
    };

    return createVendorPaymentOpportunity({
      vendorPaymentId: vendorPaymentId,
      vendorLocked: vendorLocked,
      teamId: teamId,
      ...payloadStructured,
    })
      .unwrap()
      .then((response) => {
        handleCloseModal();
        navigateToOpportunityShow(response.attributes.client_id, response.id);
        return { status: "success" };
      })
      .catch((error) => {
        handleCloseModal();
        handleErrorMessage(error);
      });
  };

  const handleSubmitMatches = useCallback(() => {
    const payload = buildMatchPayload();

    return createVendorPaymentOpportunity({
      vendorPaymentId: vendorPaymentId,
      vendorLocked: vendorLocked,
      teamId: teamId,
      ...payload,
    })
      .unwrap()
      .then((response) => {
        handleCloseModal();
        navigateToOpportunityShow(response.attributes.client_id, response.id);
        return { status: "success" };
      })
      .catch((error) => {
        handleCloseModal();
        handleErrorMessage(error);
      });
  }, [
    buildMatchPayload,
    createVendorPaymentOpportunity,
    handleErrorMessage,
    navigateToOpportunityShow,
    vendorPaymentId,
  ]);

  const matchModal = (
    <Modal
      open={activeMatchModal}
      onClose={handleCloseModal}
      title="Verify account and contact selection"
      primaryAction={{
        content: "Confirm",
        onAction: handleSubmitMatches,
      }}
    >
      <Modal.Section>
        <BlockStack>
          <Banner tone="info" title="Existing records found.">
            <p>
              We found existing records similar to what you entered. Please
              select the records you would like to use.
            </p>
          </Banner>
        </BlockStack>
      </Modal.Section>

      {matchedClient.attributes && (
        <Modal.Section>
          <BlockStack>
            <Text variant="TextMd" as="h2">Account details</Text>

            <InlineGrid columns={2} gap="200">
              <FormLayout>
                {/* <Subtext>Existing account</SubText> */}
                <Text variant="headingSm" as="h3">Existing account</Text>
                <Checkbox
                  label={
                    <>
                      <span>{matchedClient.attributes.name}</span>
                      <br />
                      <Text as="span" tone="subdued">
                        {matchedClient.attributes.billing_address ? (
                          <>
                            {matchedClient.attributes.billing_street}
                            <br />
                            {matchedClient.attributes.billing_city ||
                              "-"}, {matchedClient.attributes.billing_state}{" "}
                            {matchedClient.attributes.billing_postal_code}
                          </>
                        ) : (
                          "No billing address"
                        )}
                      </Text>
                    </>
                  }
                  checked={submitMatchedClient}
                  onChange={handleClientSelectionChange}
                />
              </FormLayout>

              <FormLayout>
                <Text variant="headingSm" as="h3">Entered account</Text>
                <Checkbox
                  label={
                    <>
                      <span>{confirmedClient.name}</span>
                      <br />
                      <Text as="span" tone="subdued">
                        {confirmedClient.billing_street}
                        <br />
                        {confirmedClient.billing_city},{" "}
                        {confirmedClient.billing_state}{" "}
                        {confirmedClient.billing_postal_code}
                      </Text>
                    </>
                  }
                  checked={!submitMatchedClient}
                  onChange={(newChecked) =>
                    handleClientSelectionChange(!newChecked)
                  }
                />
              </FormLayout>
            </InlineGrid>
          </BlockStack>
        </Modal.Section>
      )}

      {matchedVendor.attributes && (
        <Modal.Section>
          <BlockStack>
            <Text variant="TextMd" as="h2">Vendor details</Text>

            <InlineGrid columns={2} gap="200">
              <FormLayout>
                <Text variant="headingSm" as="h3">Existing vendor</Text>
                <Checkbox
                  label={
                    <>
                      <span>{matchedVendor.attributes.name}</span>
                      <br />
                      <Text as="span" tone="subdued">Vendor</Text>
                    </>
                  }
                  checked={submitMatchedVendor}
                  onChange={handleVendorSelectionChange}
                />
              </FormLayout>

              <FormLayout>
                <Text variant="headingSm" as="h3">Entered vendor</Text>
                <Checkbox
                  label={
                    <>
                      <span>{confirmedVendor.name}</span>
                      <br />
                      <Text as="span" tone="subdued">Vendor</Text>
                    </>
                  }
                  checked={!submitMatchedVendor}
                  disabled={true}
                  onChange={(newChecked) =>
                    handleVendorSelectionChange(!newChecked)
                  }
                />
              </FormLayout>
            </InlineGrid>
          </BlockStack>
        </Modal.Section>
      )}
    </Modal>
  );

  const ConfidenceBadge = (props) => {
    const { color, value } = props;
    let displayValue = "--"
    if(!isNaN(value) && value != 0) displayValue = value
    return (
      (<Badge tone={color} size="small">
        <Text variant="bodySm" as="p">{displayValue}%</Text>
      </Badge>)
    );
  };
  ConfidenceBadge.propTypes = {
    color: PropTypes.string,
    value: PropTypes.number,
  };

  const labelAction = (attribute, field, isSelect = false) => {
    const { score, ocr_score, value } = attribute || {
      score: 0,
      ocr_score: 0,
      value: null,
    };
    const { iconCritical, iconWarning, iconSuccess, iconSubdued } = {
      iconCritical: "critical", // "#d72c0d",
      iconWarning: "warning", // "#ca9500",
      iconSuccess: "success", // "#007f5f",
      iconSubdued: "subdued", //"#8c9196",
    };

    const pipColor = () => {
      if (value === null) {
        return iconSubdued;
      }

      if (ocr_score <= 0.7 || score <= 0.5) {
        return iconCritical;
      }

      if (score > 0.5 && score <= 0.7) {
        return iconWarning;
      }

      if (ocr_score >= 0.9 && score >= 0.7) {
        return iconSuccess;
      }
    };

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          paddingBottom: "8px",
        }}
      >
        <Tooltip
          content={
            score && value !== null
              ? `Fortify AI confidence score: ${Math.round(score * 100)}%`
              : `No value found for ${field}.`
          }
        >
          <ConfidenceBadge color={pipColor()} value={Math.round(score * 100)} />
        </Tooltip>
      </div>
    );
  };

  const isMultilineField = (value) => {
    if (!value) return false;
    if (value.includes("\n")) {
      return (value.match(/\n/g) || []).length + 2;
    }
    return false;
  };

  const ConfidenceField = useCallback((props) => {
    // eslint-disable-next-line react/prop-types
    const { children, suffix } = props;
    return (
      <InlineStack gap="200" wrap={false}>
        <div style={{ width: "calc(100% - 55px)" }}>{children}</div>
        <div style={{ marginTop: "30px" }}>{suffix}</div>
      </InlineStack>
    );
  }, []);

  const completeFieldsProgress = (fields) => {
    let progress = 0;
    let completeFields = [];

    for (const [key, fieldValue] of Object.entries(fields)) {
      if (fieldValue.value) {
        completeFields.push(key);
      }
    }
    progress =
      Math.round(
        (completeFields.length / Object.entries(fields).length) * 100
      ) || 0;

    return progress;
  };

  const [formProgress, setFormProgress] = useState(
    completeFieldsProgress(fields)
  );
  useEffect(() => {
    const progress = completeFieldsProgress(fields);
    setFormProgress(progress);
    return () => setFormProgress(0);
  }, [fields]);

  const FormProgressBar = ({ progress }) => {
    return <ProgressBar progress={progress} animated={false} />;
  };
  FormProgressBar.propTypes = {
    progress: PropTypes.number,
  };

  const errorBanner =
    submitErrors.length > 0 ? (
      <Banner tone="critical">
        <p>
          There were some issues with your submission. Please correct the errors
          below.
        </p>
      </Banner>
    ) : null;

  const handleCopyAccountAddress = () => {
    fields.location_address.newDefaultValue(
      fields.customer_client_billing_street.value
    );
    fields.location_city.newDefaultValue(
      fields.customer_client_billing_city.value
    );
    fields.location_state.newDefaultValue(
      fields.customer_client_billing_state.value
    );
    fields.location_zip.newDefaultValue(
      fields.customer_client_billing_postal_code.value
    );
  };

  return <>
    <Card title="Confirm fields">
      <div>
        <BlockStack>
          {errorBanner}

          {/*
          <SubText>
            <Text as="span" tone="subdued">
              {formProgress}% Complete
            </Text>
          </SubText>
          */}
           <Text variant="headingSm" as="h3">
             {formProgress}% Complete
           </Text>
          <FormProgressBar progress={formProgress} />
        </BlockStack>
      </div>

      <br/>

      <div title={vendorLocked ? "Team - Vendor locked" : "Team"}>
        <Select
          options={teams}
          value={selectedTeam?.attributes?.name}
          onChange={handleTeamSelection}
        />
      </div>

      <br/>

      <div title={"Account details"}>
        <FormLayout>
          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_client?.name,
              "customer name"
            )}
          >
            <TextField
              label={"Company name"}
              type={"text"}
              {...fields.customer_client_name}
              requiredIndicator
              multiline={isMultilineField(
                invoiceData?.customer_client?.name
              )}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_client?.billing_street,
              "street address"
            )}
          >
            <TextField
              label={"Street address"}
              type={"text"}
              {...fields.customer_client_billing_street}
              requiredIndicator
              multiline={isMultilineField(
                invoiceData?.customer_client?.billing_street
              )}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_client?.billing_city,
              "city"
            )}
          >
            <TextField
              label={"City"}
              type={"text"}
              {...fields.customer_client_billing_city}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_client?.billing_state,
              "state"
            )}
          >
            <Select
              label="State"
              placeholder={"Select state"}
              options={stateOptionsShort}
              {...fields.customer_client_billing_state}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_client?.billing_postal_code,
              "zip code"
            )}
          >
            <TextField
              label={"Zip code"}
              type={"text"}
              {...fields.customer_client_billing_postal_code}
            />
          </ConfidenceField>
        </FormLayout>
      </div>

      {/* <div title={"Contact details"}>
        <FormLayout>
          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_contact?.first_name,
              "first name"
            )}
          >
            <TextField
              label={"First name"}
              type={"text"}
              {...fields.customer_contact_first_name}
              multiline={isMultilineField(
                invoiceData?.customer_contact?.first_name?.value
              )}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_contact?.last_name,
              "last name"
            )}
          >
            <TextField
              label={"Last name"}
              type={"text"}
              {...fields.customer_contact_last_name}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_contact?.email,
              "email"
            )}
          >
            <TextField
              label={"Email"}
              type={"email"}
              {...fields.customer_contact_email}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.customer_contact?.phone,
              "phone"
            )}
          >
            <TextField
              label={"Direct phone"}
              type={"text"}
              {...fields.customer_contact_phone}
            />
          </ConfidenceField>
        </FormLayout>
      </div> */}

      <br/>

      <div title={"Quote details"}>
        <FormLayout>
          {!selectedTeam?.attributes?.vendor_locked && (
            (<ConfidenceField
              suffix={labelAction(invoiceData?.vendor_name, "vendor name")}
            >
              <TextField
                label={"Vendor name"}
                type={"text"}
                {...fields.vendor_name}
                requiredIndicator
                disabled={selectedTeam?.attributes?.vendor_locked}
              />
            </ConfidenceField>)
          )}

          <Select
            options={[
              { value: "standard", label: "Equipment financing" },
              { value: "saas", label: "Software as a service" },
            ]}
            label="Type"
            {...fields.invoice_type}
          />

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.invoice?.invoice_number,
              "quote number"
            )}
          >
            <TextField
              label={"Quote number"}
              type={"text"}
              {...fields.invoice_number}
              requiredIndicator
            />
          </ConfidenceField>

          {!invoiceData?.base_payment && (
            (<ConfidenceField
              suffix={labelAction(
                invoiceData?.invoice?.invoice_amount,
                "quote amount"
              )}
            >
              <TextField
                label={"Quote total amount"}
                type={"text"}
                {...fields.invoice_amount}
                requiredIndicator
              />
            </ConfidenceField>)
          )}

          {invoiceData?.base_payment && (
            (<ConfidenceField
              suffix={labelAction(
                invoiceData?.base_payment,
                "base payment"
              )}
            >
              <TextField
                label={"Base payment"}
                type={"text"}
                {...fields.base_payment}
                requiredIndicator
              />
            </ConfidenceField>)
          )}

          <ConfidenceField
            suffix={labelAction(invoiceData?.invoice?.sales_tax, "sales tax")}
          >
            <TextField
              label={"Sales tax"}
              type={"text"}
              {...fields.sales_tax}
              //requiredIndicator
            />
          </ConfidenceField>
        </FormLayout>
      </div>

      <br/>

      <div title={"Shipping details"}>
        <FormLayout>
          <Button onClick={handleCopyAccountAddress}>
            Copy from Account Details
          </Button>
          <ConfidenceField
            suffix={labelAction(
              invoiceData?.invoice?.location_address,
              "street address"
            )}
          >
            <TextField
              label={"Shipping street"}
              type={"text"}
              {...fields.location_address}
              requiredIndicator
              multiline={isMultilineField(
                invoiceData?.invoice?.location_address
              )}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(invoiceData?.invoice?.location_city, "city")}
          >
            <TextField
              label={"Shipping city"}
              type={"text"}
              {...fields.location_city}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.invoice?.location_state,
              "state"
            )}
          >
            <Select
              label="Shipping state"
              placeholder={"Select state"}
              options={stateOptionsShort}
              {...fields.location_state}
            />
          </ConfidenceField>

          <ConfidenceField
            suffix={labelAction(
              invoiceData?.invoice?.location_zip,
              "zip code"
            )}
          >
            <TextField
              label={"Zip code"}
              type={"text"}
              {...fields.location_zip}
            />
          </ConfidenceField>
        </FormLayout>
      </div>
    </Card>
    {matchModal}
  </>;
});
InvoiceConfirmForm.displayName = "InvoiceConfirmForm";

InvoiceConfirmForm.propTypes = {
  handleErrorMessage: PropTypes.func,
  invoiceData: PropTypes.object,
  onConfirmationLoading: PropTypes.func,
  vendorPaymentId: PropTypes.string,
};

export default InvoiceConfirmForm;
